var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"danger"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Customer ")],1),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"}},[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":""}},[_vm._v(" Import ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":""}},[_vm._v(" Export ")])],1)],1)]),_c('v-card-title',[_vm._v(" Customers "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.desserts,"item-key":"name","show-select":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30","dark":"","tile":""}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',[_c('p',{staticClass:"mb-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',[_c('v-icon',{attrs:{"x-small":"","color":"warning"}},[_vm._v("mdi-star")]),_c('v-icon',{attrs:{"x-small":"","color":"warning"}},[_vm._v("mdi-star")]),_c('v-icon',{attrs:{"x-small":"","color":"warning"}},[_vm._v("mdi-star")]),_c('v-icon',{attrs:{"x-small":"","color":"warning"}},[_vm._v("mdi-star")]),_c('v-icon',{attrs:{"x-small":"","color":"warning"}},[_vm._v("mdi-star")])],1)])],1)]}},{key:"item.badge",fn:function(ref){
var item = ref.item;
return [(item.badge === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(item.badge)+" ")],1)]:[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(item.badge)+" ")],1)]]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Check")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }